<template>
  <div class="main">

    <a-form
      id="formLogin"
      class="user-layout-login"
      ref="formLogin"
      :form="form"
      @submit="handleSubmit"
    >

    <div style="float:left;width: 50%;height: 100%;background-color: #ECF6FF;text-align: center;padding-top: 90px;border-top-left-radius:10px;border-bottom-left-radius:10px;"><img style="width: 259px;height: 346px" src="~@/assets/loginmain.svg"></div>
    <div style="float:right;width: 50%;height: 100%;background-color: #ffffff;border-top-right-radius:10px;border-bottom-right-radius:10px">
      <div v-show="showwx" style="height: 90%;">
        <!--登录切换-->
        <div style="height: 70px;width: 100%;">
          <div style="float:left;width: 330px;">
            <div style="float:right;margin-top:50px ;height: 30px;width: 100px;text-align:center;background-color: #e6f7ff;padding-top: 5px;color: #2593FC">账号登录</div>
          </div>
          <div style="float: right">
            <img src="~@/assets/pc.png" style="width: 50px;height: 50px;margin-right: 20px;margin-top: 20px" @click="showwxfunction(false)"></img>
          </div>
        </div>
        <div style="height: 38px;width: 120px;margin-left: 40px;margin-top: 10px;font-weight: bold;font-size: 18px;">微信扫码登录</div>
        <div style="height: 260px;width: 100%;" >
          <div id="qrcode" ref="qrcode"   style="vertical-align: middle;width:220px;height:220px;text-align:center;margin-left: 100px;margin-top: 30px" @click="refurbishwxcode"></div>
        </div>
        <div style="width: 100%; text-align: center;padding-top: 10px">请使用微信扫码二维码登录</div>
        <div style="width: 100%; text-align: center;padding-top: 10px">若页面长时间未跳转，请尝试<a href="#" @click="wxlogin()">点击此处</a></div>
        <div style="width: 100%; text-align: center;padding-top: 10px">若码过期请点击二维码图片更换</div>
      </div>

      <div  v-show="!showwx" style="height: 90%;text-align: center">
        <!--登录切换-->
        <div style="height: 70px;width: 100%;">
          <div style="float:left;width: 330px;">
            <div style="float:right;margin-top:50px ;height: 30px;width: 90px;background-color: #e6f7ff;padding-top: 5px;color: #2593FC">微信扫码登录</div>
          </div>
          <div style="float: right">
            <img src="~@/assets/qrcode.png" style="width: 50px;height: 50px;margin-right: 20px;margin-top: 20px"  @click="showwxfunction(true)"></img>
          </div>
        </div>
        <div>
          <a-tabs
            :activeKey="customActiveKey"
            style="width: 350px;padding-left: 60px;margin-top: 100px"
            :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
            @change="handleTabClick"
          >
            <a-tab-pane key="tab1" tab="账号密码登录">
              <a-form-item>
                <a-input size="large" type="text" placeholder="手机号" v-decorator="['mobile', {rules: [{ required: true, pattern: /^1[34578]\d{9}$/, message: '请输入正确的手机号' }], validateTrigger: 'change'}]">
                  <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-input-password size="large" type="text" placeholder="密码"
                                  v-decorator="['password', {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur'}]"
                                  oninput = "value=value.replace(/[\u4E00-\u9FA5]/g,'')"
                >
                  <a-icon slot="prefix" type="key" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                </a-input-password>
              </a-form-item>
            </a-tab-pane>

            <a-tab-pane key="tab2" tab="手机号登录">
              <a-form-item>
                <a-input size="large" type="text" placeholder="手机号" v-decorator="['mobile', {rules: [{ required: true, pattern: /^1[34578]\d{9}$/, message: '请输入正确的手机号' }], validateTrigger: 'change'}]">
                  <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                </a-input>
              </a-form-item>
<!--              <a-row :gutter="16">-->
<!--                <a-col class="gutter-row" :span="14">-->
                <div style="width: 65%;float: left">
                  <a-form-item>
                    <a-input size="large" type="text" placeholder="验证码" v-decorator="['captcha', {rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur'}]">
                      <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                    </a-input>
                  </a-form-item>
                </div>
<!--                </a-col>-->
<!--                <a-col class="gutter-row" :span="10">-->
                <div style="width: 35%;float: left">
                  <a-button
                    class="getCaptcha"
                    tabindex="-1"
                    :disabled="state.smsSendBtn"
                    @click.stop.prevent="getCaptcha"
                    v-text="!state.smsSendBtn && '获取验证码' || (state.time+' s')"
                  ></a-button>
                </div>
<!--                </a-col>-->
<!--              </a-row>-->
            </a-tab-pane>
          </a-tabs>
          <a-form-item style="margin-top:24px">
            <a-button
              size="large"
              type="primary"
              htmlType="submit"
              style="width: 300px;text-align: center"
            >确定</a-button>
          </a-form-item>
        </div>
      </div>
      <div style="margin-top: 15px;padding-left: 160px">自动登录：<a-switch v-model="autoLogin"  v-decorator="['autoLogin']" @change="changeAutoLogin">自动登录</a-switch></div>
    </div>
    </a-form>
    <div style="padding-top: 30px;font-size: 13px;color: white;text-align: center;opacity:0.7">——冰鸽冷链服务商管理平台——</div>
    <a-modal
      title="请选择登录企业"
      :width="440"
      :visible="visible2"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="changeOrgHandleCancel"
      :centered="true"
      :footer="null"
      :mask="true"
      :maskClosable="false"
      :body-style="{height:'300px'}"
    >
      <div style="width: 100%;text-align: center;margin-top: 60px">
        <a-select  v-model="selectedorg.name" defaultValue="请选择登录企业" placeholder="请选择登录企业" style="width: 340px" @change="handleChange">
          <a-select-option v-for="(item, index) in orgsList" :key="index" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
  </div>

</template>

<script>
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import {getSmsCaptcha, get2step, changeOrg, initRandomCode, login, validateToken} from '@/api/login'
import QRCode from 'qrcodejs2'
import Vue from "vue";
import {ACCESS_TOKEN, SIDEBAR_TYPE} from "@/store/mutation-types";
// import { Modal } from 'ant-design-vue'
export default {
  components: {
    TwoStepCaptcha
  },
  data () {
    return {
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      showwx:true,//是否显示微信登录
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      qrcodelogin:true,
      visible2:false,
      selectedorg: {id:undefined,name:undefined},
      confirmLoading: false,
      orgsList:[],
      qrcode: '',
      randomCode:null,
      autoLogin:false,
      interval:null,//短信发送定时器
      state: {
        time: 60,
        smsSendBtn: false
      },
    }
  },
  watch:{
    randomCode:{
      handler(newName, oldName) {
        this.$nextTick(function () {
          if(document.getElementById('qrcode')!=null){
            document.getElementById('qrcode').innerHTML = ''
            let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6689a961ce9c4843&" +
              "redirect_uri="+this.domain+"%2Fly_user_center%2Fsso%2Fwxlogin%3Ftype%3Dly%26randomCode%3D" +newName+
              "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
            this.qrcode = new QRCode('qrcode', {
              width: 220,
              height: 220,
              colorDark: '#000',
              colorLight: '#fff',
              correctLevel: QRCode.CorrectLevel.L, // 容错率，L/M/H
              text: url
            })
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['Login', 'Logout','ChangeOrg','FindOrgs']),
    // handler
    showwxfunction(showwx){
      this.showwx = showwx
      this.customActiveKey = "tab1"
    },
    handleTabClick (key) {
      this.customActiveKey = key
      // this.form.resetFields()
      if(this.customActiveKey=="tab1"){
        this.showLoginWxCode()
      }else{
        //清除之前的短信发送定时器
        clearInterval(this.interval)
        this.interval = null
      }
    },
    handleSubmit (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        customActiveKey,
        Login
      } = this

      const validateFieldsKey = customActiveKey === 'tab1' ? ['mobile', 'password'] : ['mobile', 'captcha']

      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          console.log('login form', values)
          const loginParams = { ...values }
          // loginParams.password = md5(values.password)
          loginParams.password = values.password
          loginParams.type = 3
          loginParams.target = 2
          loginParams.verificationCode = loginParams.captcha
          console.info(this.customActiveKey)
          if(customActiveKey=="tab1"){
            loginParams.type = 3
            loginParams.loginName = loginParams.mobile
          }else{
            loginParams.type =2
            loginParams.smsType=2
          }

          let autologinparm = 0
          if(this.autoLogin ==true){
            autologinparm=1
          }
          loginParams.autoLogin=autologinparm
          login(loginParams)
          .then((res) =>{
            if(res!=null&&res.code==0){
                this.loginSuccess(res)
             }else{
              this.$message.error(res.message);
             }
          })
          .catch(err => this.requestFailed(err))
          .finally(() => {
            state.loginBtn = false
          })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    getCaptcha (e) {
      e.preventDefault()
      const { form: { validateFields }, state } = this
      let that = this
      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true
          this.interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              this.state.time = 60
              this.state.smsSendBtn = false
              window.clearInterval(this.interval)
            }
          }, 1000)

          const hide = this.$message.loading('验证码发送中..', 0)
          getSmsCaptcha({ mobile: values.mobile,smsType: 2  }).then(res => {
            if(res.code!=0){
              that.$message.error(res.message)
              setTimeout(hide, 1)
              clearInterval(this.interval)
              this.state.time = 60
              this.state.smsSendBtn = false
              return false
            }
            setTimeout(hide, 2500)
            this.$notification['success']({
              message: '提示',
              description: '验证码获取成功',
              duration: 8
            })
          }).catch(err => {
            setTimeout(hide, 1)
            clearInterval(this.interval)
            this.state.time = 60
            this.state.smsSendBtn = false
            this.requestFailed(err)
          })
        }
      })
    },
    stepCaptchaSuccess () {
      //this.loginSuccess()
    },
    stepCaptchaCancel () {
      this.Logout().then(() => {
        this.loginBtn = false
        this.stepCaptchaVisible = false
      })
    },
    loginSuccess (res) {
      console.log("----"+res)
      //token存入session
      Vue.ls.set(ACCESS_TOKEN, res.result)
      //如果没登录企业，就先选择要登录的企业（）弹窗对话框
      clearInterval(this.interval)
      this.interval = true;
      this.showChangeOrg()
      //如果勾选自动登录，保存当前token在localStorage,否则清楚localStorage
      if(this.autoLogin == true){
        localStorage.setItem('autoLoginCode', res.result);
      }else{
        localStorage.removeItem('autoLoginCode')
      }
      return false
    },
    requestFailed (err) {
      this.isLoginError = true
      this.$notification['error']({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
    },
    showLoginWxCode () {
      let that = this
      this.visible = true
      let code = ""
      var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
      for (var i = 0; i < 6; i++) {
        var index = Math.floor(Math.random() * 9);
        code += random[index]
      }
      this.randomCode = code;
      //将code存入redis,然后定时请求后台查看是否已经登录
      initRandomCode({ randomCode: code }).then(res => {
        if(that.interval){
          clearInterval(that.interval)
          that.interval = null
        }
        that.interval = setInterval(() => {
          this.wxlogin();
        }, 5000)
      })
    },
    changeOrgHandleCancel () {
      this.visible2 = false

    },
    showChangeOrg () {
      this.visible2 = true
      let that = this;
      //加载该用户所关联的企业列表
      const {
        FindOrgs
      } = this

      FindOrgs().then((res) => {
        that.orgsList = res
        if(that.orgsList.length==1){
          that.selectedorg = {}
          that.selectedorg.id = that.orgsList[0].id;
          that.selectedorg.name = that.orgsList[0].name;
          that.handleChange(that.orgsList[0].id);
        }else if(that.orgsList.length<=0){
          that.$message.error("当前用户所在服务商已禁用，或未加入服务商");
          return false
        }else{
          let selectedOrgId = localStorage.getItem("selectedOrgId");
          if(selectedOrgId!=undefined&&selectedOrgId!=null&&selectedOrgId!=""){
            let iscanchange = false
            for(let i in that.orgsList ){
              if(that.orgsList[i].id ==selectedOrgId ){
                iscanchange = true;
                that.selectedorg = {}
                that.selectedorg.id = selectedOrgId;
                that.selectedorg.name = that.orgsList[i].name;
                break
              }
            }
            if(iscanchange){
              that.handleChange(selectedOrgId);
            }
          }
        }
      })
    },
    handleChange (value) {
      console.log(`selected ${value}`);
      let that = this;
      const {
        ChangeOrg
      } = this
      localStorage.setItem("selectedOrgId",value)
      ChangeOrg({id:value}).then((res) => {
        Vue.ls.set("orgName", res.orgName)
        Vue.ls.set("csId", res.csId)
        Vue.ls.set("csQrcode", res.csQrcode)
        Vue.ls.set("csTel", res.csTel)
        that.$router.push({ path: '/' })
        setTimeout(() => {
          that.$notification.success({
            message: '欢迎',
            description: `${timeFix()}，欢迎回来`
          })
        }, 1000)
        that.isLoginError = false
      })
    },
    wxlogin(){
      let that = this
      let autologinparm = 0
      if(this.autoLogin ==true){
        autologinparm=1
      }
      let loginParams = {type:1,target:2,randomCode:this.randomCode,autoLogin:autologinparm}
      login(loginParams)
        .then((res) =>{
          if(res!=null&&res.code==0){
            this.loginSuccess(res)
          }else{
            //this.$message.error(res.message);
            if(res.message=="请使用微信扫码"){
                //document.getElementById("showloginerror").innerText = "请使用微信扫码"
            }else{
              this.$message.error(res.message);
            }
          }
        })
        .catch(err => this.requestFailed(err))
        .finally(() => {
          that.loginBtn = false
        })

    },
    refurbishwxcode(){
      this.showLoginWxCode()
    },
    changeAutoLogin(e){
      if(e == true){
        this.autoLogin=true
      }else{
        this.autoLogin=false
      }
    },
    validateToken(token){
      let that = this
      validateToken(token).then((res)=>{
        if(res.code==0){
          that.loginSuccess({result:token})
        }else{
          localStorage.removeItem('autoLoginCode')
        }
      })
    }

  },
  mounted: function(){
    let that = this
    console.log("----"+this.domain)
    let token = Vue.ls.get(ACCESS_TOKEN)
    let autoLoginCode = localStorage.getItem("autoLoginCode");
    if(token==undefined||token==null||token==""){
      //let autoLoginCode = this.$cookies.get("autoLoginCode");
      token = autoLoginCode
      Vue.ls.set(ACCESS_TOKEN, autoLoginCode)
    }
    if(autoLoginCode!=undefined&&autoLoginCode!=null&&autoLoginCode!=""){
      this.autoLogin = true
    }else{
      this.autoLogin = false
    }
    if(token!=null){
      this.validateToken(token)
    }

    //如果处于微信登录标签下定时重新生成二维码
    if(this.customActiveKey=="tab1"){
      this.showLoginWxCode()
    }
  }
}
</script>

<style lang="less" scoped>
.user-layout-login {
  height: 542px;
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
.web {
  .ant-modal-content {
    position: relative;
    background-color: #00000000 !important;
    border: 0;
    border-radius: 4px;
    background-clip: padding-box;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  }
}

</style>
